import React, { useContext, useState } from "react";
import Modal from "react-modal";
import moment from "moment";
import { convertTimezone } from "../helpers/common";
import eventContext from "../contexts/EventContext";
import AgendaSpeakerModal from "./AgendaSpeakerModal";

const customStyles = {
	content: {
		top: "50%",
		left: "50%",
		right: "auto",
		bottom: "auto",
		marginRight: "-50%",
		transform: "translate(-50%, -50%)",
		width: "90%",
		maxWidth: "770px",
		maxHeight: "456px",
		overflow: "auto",
	},
};



const AgendaCard = ({ isOpen, closeModal, sessionDetails, professionalTemplate }) => {
	const { eventData } = useContext(eventContext);
	const [activeSpeaker, setActiveSpeaker] = useState(null);

	const getDate = (date, format) => {
		return moment(convertTimezone(date, eventData.time_zone)).format(format);
	};

	const userTimezone = moment.tz.guess();

	const sessionTime = (val) => {
		let startTime = getDate(`${val.start_timestamp}`, "h:mm A");
		let endTime = getDate(`${val.end_timestamp}`, "h:mm A");
		let timezone = moment.tz(`${val.end_timestamp}`, userTimezone).format("z");
		return `${startTime} to ${endTime} ${timezone}`;
	};

	function convertToHTML(val) {
		return {
			__html: val?.session_description?.replace(/(?:\r\n|\r|\n)/g, "") || "",
		};
	}

	return (
		<>
			<Modal
				isOpen={isOpen}
				onRequestClose={() => closeModal()}
				shouldCloseOnEsc={false}
				shouldCloseOnOverlayClick={true}
				style={customStyles}
			>
				<div className="row">
					<div>
						<i
							className="fas fa-times closeIcon d-flex justify-content-end"
							onClick={() => closeModal()}
						></i>
					</div>
					<div className="agenda-modal-title-section mt-2">
						<div className="d-flex justify-content-between">
							<span className="agenda-modal-title">
								{sessionDetails.session_title}
							</span>
						</div>
					</div>
					<div className="agenda-modal-date-section mt-2">
						<span className="agenda-modal-date">{sessionTime(sessionDetails)}</span>
					</div>
					{sessionDetails.session_location && (
						<p className="speaker-organisation gap-2 d-flex mt-2">
							<img src="/img/prolocation.svg" alt="" />
							{sessionDetails.session_location}
						</p>
					)}
					{sessionDetails.session_description &&
						<div className="col-12 agenda-modal-description-section">
							<p
								className="agenda-modal-description"
								dangerouslySetInnerHTML={convertToHTML(sessionDetails)}
							/>
						</div>
					}
					{sessionDetails.session_speakers.length > 0 &&
						sessionDetails.session_speakers.map(val => (
							<div className="col-md-6 agenda-speaker-section" onClick={() => setActiveSpeaker(val)}>
								<div className="agenda-speaker d-flex align-items-center">
									<img src={val.image_url} className="agends-speaker-img" />
									<div className="d-flex flex-column about-speaker-div">
										<span className="speaker-name">{val.name}</span>
										<span className="speaker-designation">
											{val.designation}
										</span>
										<span className="speaker-designation">
											{val.organisation}
										</span>
										<div className="d-flex flex-row">
											{val.facebook_url && (
												<div
													className="me-2"
												>
													<img className="social-image" src="/img/facebook01.svg" alt="" />
												</div>
											)}
											{val.twitter_url && (
												<div
													className="me-2"
												>
													<img
														className="social-image"
														src="/img/twitter-mini.svg"
														alt=""
													/>
												</div>
											)}
											{val.linkedin_url && (
												<div
													className="me-2"
												>
													<img className="social-image" src="/img/linkedin01.svg" alt="" />
												</div>
											)}
											{val.website_url && (
												<div
													className="me-2"
												>
													<img className="social-image" src="/img/Website01.svg" alt="" />
												</div>
											)}
										</div>
									</div>
								</div>
							</div>
						))}
					{sessionDetails.tags?.length > 0 && (
						<div className="mt-3">
							<p className="tag-header">Tags</p>
							<div className="d-flex flex-wrap gap-2 mt-2 mb-5">
								{sessionDetails.tags.map((tag) => (
									<div key={tag.id} className="tag-container">
										#{tag.name}
									</div>
								))}
							</div>
						</div>
					)}
				</div>
			</Modal>
			{activeSpeaker && (
				<AgendaSpeakerModal
					isOpen={!!activeSpeaker}
					closeModal={() => setActiveSpeaker(null)}
					speaker={activeSpeaker}
					customStyles={customStyles}
					professionalTemplate={professionalTemplate}
				/>
			)}
			<style jsx>{`
			    .social-image{
					width: 16px;
					height: 16px;
				}
				.closeIcon {
					font-size: 1.5rem;
					color: black;
					opacity: 0.5;
					cursor: pointer;
				}
				.agenda-modal-title {
					font-family: "Prompt";
					font-weight: 400;
					font-size: 24px;
					line-height: 33px;
					color: #141B25;
				}

				.agenda-modal-date-section {
					// margin-right: 1px;
				}
				.agenda-modal-date {
					font-family: "Prompt";
					font-weight: 500;
					font-size: 18px;
					line-height: 27px;
					color: ${professionalTemplate ? "#FDB14B" : "#FB5850"};
					border-radius: 5px;
					line-height: 36px;
				}
				.agenda-modal-description-section {
					margin-top: 20px;
				}
				.agenda-modal-description {
					font-family: "Prompt";
					font-weight: 400;
					font-size: 16px;
					line-height: 24px;
					color: #333333;
				}
				.agenda-speaker-section {
					margin-top: 20px;
				}
				.agenda-speaker {
					border-radius: 5px;
					border: 1px solid #00000033;
					padding: 5px 10px;
					height: 100%;
				}
				.agends-speaker-img {
					width: 80px;
					height: 80px;
					border-radius: 5px;
				}
				.about-speaker-div {
					margin-left: 15px;
				}
				.speaker-name {
					font-family: "Prompt";
					font-weight: 500;
					font-size: 16px;
					line-height: 21px;
					color: #333333;
				}
				.speaker-designation {
					font-family: "Prompt";
					font-weight: 400;
					font-size: 12px;
					line-height: 18px;
					color: #333333;
				}
				.social-icon {
					color: #333333;
				}
				.tag-header{
                  font-family: "Prompt";
                  font-weight: 500;
                  font-size: 16px;
                  line-height: 24px;
                  color: #141B25;
                }
                .tag-container{
                  background-color:${professionalTemplate ? "#FDB14B14" : "#FFF2F1"};
                  height: 28px;
                  padding: 0px 12px 0px 12px;
                  border-radius: 4px;
                }
                .tag-name{
                  font-family: "Hind";
                  font-weight: 500;
                  font-size: 12px;
                  line-height: 20px;
                  color: #212529;
                }
			`}</style>
		</>
	);
};

export default AgendaCard;
