import { AnimatePresence, motion } from 'framer-motion'
import React from 'react'

const SideViewModal = ({ children, isOpen, setIsOpen, background = "#FFF", isMenu = false }) => {
    return (
        <>
            <AnimatePresence>
                {isOpen && (
                    <>
                        <motion.div
                            className="position-fixed h-100 top-0 end-0 side-view-modal-cont"
                            initial={{ x: "100%" }}
                            animate={{
                                x: 0
                            }}
                            exit={{
                                x: "100%"
                            }}
                            transition={{ type: "easy-in-out", bounce: 0, duration: 0.5 }}
                        >
                            {children}
                        </motion.div>
                        <motion.div
                            initial={{ opacity: 0 }}
                            animate={{
                                opacity: 1
                            }}
                            exit={{
                                opacity: 0
                            }}
                            transition={{ type: "spring", bounce: 0, duration: 0.2 }}
                            onClick={() => setIsOpen((isOpen) => !isOpen)}
                            style={{ background: "rgba(0, 0, 0, 0.75)", zIndex: "3001" }}
                            className="px-5 position-fixed h-100 vw-100 d-flex align-items-center justify-content-center top-0 start-0"
                        />
                    </>
                )}
            </AnimatePresence>
            <style>{`
				.side-view-modal-cont {
					background: ${background};
					z-index: 3002;
					width: 50%;
					max-width: 700px;
					min-width: 320px;
					overflow-y: auto;
				}

                @media (max-width: 1200px) {
                    .side-view-modal-cont {
                        width: 60%;
                        z-index: 3002; 
                        max-width: ${isMenu ? "320px" : "600px"};
                        min-width: 320px;
                        overflow-y: auto;
                    }
                }

                @media (max-width: 768px) {
                    .side-view-modal-cont {
                        width: 80%;
                        z-index: 3002; 
                        max-width: ${isMenu ? "320px" : "600px"};
                        min-width: 320px;
                        overflow-y: auto;
                    }
                }
            `}</style>
        </>
    )
}

export default SideViewModal
