import React, { useContext } from 'react'
import eventContext from '../../../contexts/EventContext'
import Link from "next/link";
import MobileNavbar from '../../../components/NavigationBar/MobileNavbar';
import AuthContext from '../../../contexts/AuthContext';

const EventNavBar = ({ eventUrl, currentUrl, ticketData, hasCoupons, hasAccessCodes, showNavbar }) => {

    const { eventData } = useContext(eventContext)
    const authCtx = useContext(AuthContext)
    const [cookiePopupHeight, setCookiePopupHeight] = React.useState();

    React.useEffect(() => {
        setTimeout(() => {
            if (document.getElementById('cookie-popup-container') != null) {
                const height = document.getElementById('cookie-popup-container').clientHeight;
                setCookiePopupHeight(height);
                window.addEventListener('resize', () => {
                    if (document.getElementById('cookie-popup-container') != null) {
                        const height = document.getElementById('cookie-popup-container').clientHeight;
                        setCookiePopupHeight(height);
                    }
                })
            }
        }, 5000)
    }, [])


    return (
        <>  <div className='d-flex justify-content-center align-items-center'>


            <span className="nav-container d-flex justify-content-center align-items-center">
                <div className={"nav-bar px-2 d-flex justify-content-center align-items-center"}>
                    {eventData.description && (
                        <div className="col-4 nav-options d-flex justify-content-center align-items-center mx-2">
                            <Link href={`${currentUrl}#about`} scroll={false}>
                                <span>About</span>
                            </Link>
                        </div>)
                    }
                    {!!eventData.is_virtual && eventData.event_live_link ?
                        <div className="col-4 nav-options d-flex justify-content-center align-items-center text-nowrap mx-3">
                            <span>
                                <a href={eventData.event_live_link} target="_blank" style={{ textDecoration: 'none' }}>Event Website</a>
                            </span>
                        </div> :
                        eventData?.venue && eventData.is_virtual === false && eventData.event_live_link === null ?
                            <div className="col-4 nav-options d-flex justify-content-center align-items-center mx-3">
                                <Link href={`${currentUrl}#venue`} scroll={false}>
                                    <span>Venue</span>
                                </Link>
                            </div> : null
                    }
                    {(ticketData && ticketData.length > 0 || hasAccessCodes) && (
                        <div className="col-4 nav-options d-flex justify-content-center align-items-center mx-3">
                            <Link href={`${currentUrl}#tickets`} scroll={false}>
                                <span>{ticketData.length > 1 ? 'Tickets' : 'Ticket'}</span>
                            </Link>
                        </div>
                    )}
                    {!!eventData?.has_speakers && (
                        <div className="col-4 nav-options d-flex justify-content-center align-items-center mx-3">
                            <Link href={`${currentUrl}#speakers`} scroll={false}>
                                <span>{eventData?.speaker_section_title !== "" && eventData?.speaker_section_title ? eventData?.speaker_section_title : (
                                    "Speakers"
                                )}</span>
                            </Link>
                        </div>)
                    }
                    {!!eventData?.has_workshops && (
                        <div className="col-4 nav-options d-flex justify-content-center align-items-center mx-3">
                            <Link href={`${currentUrl}#workshops`} scroll={false}>
                                <span>{eventData?.workshop_section_title !== "" && eventData?.workshop_section_title ? eventData?.workshop_section_title : (
                                    "Workshops"
                                )}</span>
                            </Link>
                        </div>)
                    }

                    {!!eventData?.has_sponsors && (
                        <div className="col-4 nav-options d-flex justify-content-center align-items-center mx-3">
                            <Link href={`${currentUrl}#sponsors`} scroll={false}>
                                <span>{eventData?.sponsor_section_title !== "" && eventData?.sponsor_section_title ? eventData?.sponsor_section_title : (
                                    "Sponsors"
                                )}</span>
                            </Link>
                        </div>)
                    }

                    {!!eventData?.has_partners && (
                        <div className="col-4 nav-options d-flex justify-content-center align-items-center mx-3">
                            <Link href={`${currentUrl}#partners`} scroll={false}>
                                <span>{eventData?.partner_section_title !== "" && eventData?.partner_section_title ? eventData?.partner_section_title : (
                                    "Partners"
                                )}</span>
                            </Link>
                        </div>)
                    }
                    {!!eventData?.has_exhibitors && (
                        <div className="col-4 nav-options d-flex justify-content-center align-items-center mx-3">
                            <Link href={`${currentUrl}#exhibitors`} scroll={false}>
                                <span>{eventData?.exhibitor_section_title !== "" && eventData?.exhibitor_section_title ? eventData?.exhibitor_section_title : (
                                    "Exhibitors"
                                )}</span>
                            </Link>
                        </div>)
                    }

                    {!!eventData?.has_agenda && (
                        <div className="col-4 nav-options d-flex justify-content-center align-items-center mx-3">
                            <Link href={`${currentUrl}#agenda`} scroll={false}>
                                <span>Agenda</span>
                            </Link>
                        </div>)
                    }


                </div>
                {/* <div className="mobile-nav">
                    <div className="mob-nav-options d-flex flex-column justify-content-around align-items-center">
                        <i className="fa fa-home fa-2x pt-2" aria-hidden="true"></i>
                        <span>Home</span>
                    </div>
                    <div className="mob-nav-options d-flex flex-column justify-content-around align-items-center">
                        <i className="fa fa-calendar fa-2x pt-2" aria-hidden="true"></i>
                        <span>Events</span>
                    </div>
                    <div className="mob-nav-options d-flex flex-column justify-content-around align-items-center">
                        <i className="fa fa-camera fa-2x pt-2" aria-hidden="true"></i>
                        <span>Resources</span>
                    </div>
                    <div className="mob-nav-options d-flex flex-column justify-content-around align-items-center">
                        <i className="fa fa-bars fa-2x pt-2" aria-hidden="true"></i>
                        <span>More</span>
                    </div>
               </div> */}
                <MobileNavbar />
            </span>
        </div>
            <style>{`
                .mobile-nav{
                    display:none;
                }
                .nav-container{
                    position: fixed;
                    bottom: ${authCtx.hasCookiesAccepted ? '10px' : `${cookiePopupHeight ? cookiePopupHeight : "10"}px`};
                    height: 70px;
                    width: auto;
                    z-index: 100;
                }
                .nav-bar{
                    width: auto;
                    height: 55px;
                    background: rgba(87, 33, 72, 0.65);
                    box-shadow: 0px 4px 15px 0px rgba(0, 0, 0, 0.15);
                    backdrop-filter: blur(50px);
                    border-radius: 40px;
                }
                .nav-options{
                    cursor:pointer;
                    height: 100%;
                    width: auto;
                }
                .nav-options:hover{
                    border-bottom: 4px solid #F63C3D;
                }
                .nav-options span, .nav-options span a{
                    font-family: Prompt;
                    font-style: normal;
                    font-weight: 600;
                    font-size: 20px;
                    line-height: 38px;
                    letter-spacing: 0.04em;
                    color: #FFFFFF;
                }
                 @media only screen and (max-width: 761px){
                    .nav-options span, .nav-options span a {
                        font-size: 14.5px;
                    }
                 }
                @media only screen and (max-width: 576px){
                    .nav-bar{
                        display: none !important;
                    }
                    .mobile-nav{
                        display:flex;
                        width: 100%;
                        height: 100%;
                        background: rgba(55, 21, 46, 0.8);
                        box-shadow: 0px 4px 15px 0px rgba(0, 0, 0, 0.15);
                        backdrop-filter: blur(50px);
                        border-radius: 25px 25px 0px 0px;
                        color: #fff;
                    }
                    .mob-nav-options{
                        height: 100%;
                        width: 25%;
                    }
                    .mob-nav-options:hover{
                        color: #FB5850;
                    }
                    .mob-nav-options span{
                        font-family: Prompt;
                        font-style: normal;
                        font-weight: normal;
                        font-size: 12px;
                        line-height: 18px;
                        text-align: center;
                        color: #FFFFFF;
                    }
                }
            `}
            </style>
        </>
    )
}

export default EventNavBar
