import React, { useEffect } from 'react'
import ProSideViewModal from './ProSideViewModal'

const SideViewSponsorPro = ({ openModal, setOpenModal, modalData }) => {
    return (
        <ProSideViewModal isOpen={openModal} setIsOpen={setOpenModal} background="#FFFFFF">
            <div className="p-4 w-100 modal-container">
                <div className="text-end mb-3">
                    <button
                        onClick={() => setOpenModal(false)}

                        className="border-0"
                        style={{
                            background: "#FFFFFF",
                            color: "#525252"
                        }}
                    >
                        <i className="fa-solid fa-xmark" style={{ fontSize: "18px" }}></i>
                    </button>
                </div>
                <div className="d-flex mb-4 sponsor-box justify-content-between">
                    <div className="d-flex flex-row">
                        <div className="sponsor-image rounded-circle me-3 d-flex align-items-center">
                            <img
                                src={modalData?.image_url}
                                alt={modalData?.name}
                                className="image-size"
                            />
                        </div>
                        <div className="d-flex flex-column justify-content-center sponsor-info">
                            <p className="sponsor-name">{modalData?.entity_name}</p>
                            <p className="location">{modalData?.location}</p>
                        </div>
                    </div>
                    <div className="d-flex text-end">
                        {modalData?.website_url &&
                            <img onClick={() => window.open(modalData?.website_url, "_blank")} src="/img/minimallocation.svg" style={{ width: "18px", height: "18px", margin: "10px" }} alt="link" />
                        }
                    </div>
                </div>
                {modalData?.description &&
                    <div className="sponsor-description mb-4">
                        <p
                            dangerouslySetInnerHTML={{
                                __html: `${modalData?.description?.replace(/(?:\r\n|\r|\n)/g, "") || ""}`
                            }}
                        />
                    </div>
                }
                {modalData?.booth_number &&
                    <div className="d-flex flex-column align-items-center mb-4">
                        <div className="booth-box d-flex justify-content-center align-items-center mb-2">{modalData?.booth_number}</div>
                        <p className="booth-text">Booth Number</p>
                    </div>
                }
                {modalData?.poc &&
                    <div className="d-flex flex-column mb-4 p-3 poc-box">
                        <div className="mb-2 contact-text">Contact Us</div>
                        <div className="booth-user-box d-flex flex-row mb-3">
                            {modalData?.poc?.image_url &&
                                <img className='poc-image' src={modalData?.poc?.image_url} alt="POC Image" />
                            }
                            <div className="d-flex flex-column justify-content-center">
                                <div className="poc-name">{modalData?.poc?.name}</div>
                                <p className="booth-text">
                                    <span className='poc-designation'>{modalData?.poc?.designation}</span>, <span className='poc-organization'>{modalData?.poc?.organization}</span>
                                </p>
                            </div>
                        </div>
                        <div className="booth-contact-box d-flex flex-column">
                            {modalData?.poc?.contact_number &&
                                <div className="d-flex flex-row mb-2 align-items-center">
                                    <img className='contact-image' src="img/minimal-phone-call.svg" alt="POC Image" />
                                    <div className="">{modalData?.poc?.dial_code} {modalData?.poc?.contact_number}</div>
                                </div>
                            }
                            {modalData?.poc?.email &&
                                <div className="d-flex flex-row mb-2 align-items-center">
                                    <img className='contact-image' src="img/minimal-mail.svg" alt="POC Image" />
                                    <div className="booth-">{modalData?.poc?.email}</div>
                                </div>
                            }
                        </div>
                    </div>
                }

                {modalData?.representatives && modalData?.representatives?.length > 0 &&
                    <div className="mb-4">
                        <div className="mb-2 representative-text">Representative(s)</div>
                        <div className="d-flex flex-row flex-wrap gap-5">
                            {modalData?.representatives
                                ?.sort((a, b) => a.representative_order - b.representative_order) // Sort by representative_order
                                .map((representative) => (
                                    <div
                                        key={representative?.representative_order}
                                        className="speakers-box d-flex flex-column align-items-center"
                                        id={representative?.representative_order}
                                    >
                                        <img
                                            className="speaker-image"
                                            src={representative?.image_url}
                                            alt="speaker"
                                        />
                                        <div className="d-flex flex-column align-items-center">
                                            <div className="speaker-name">{representative?.name}</div>
                                            <p className="speaker-main">
                                                <span className='speaker-designation'>{representative?.designation}</span>,{" "}
                                                <span className='speaker-organization'>{representative?.organization}</span>
                                            </p>
                                        </div>
                                    </div>
                                ))}
                        </div>
                    </div>}
                {modalData?.tags && modalData?.tags?.length > 0 && (
                    <div className="speaker-tags">
                        <p className="my-3 tag-header">Tags</p>
                        <div className="d-flex flex-wrap my-3">
                            {modalData?.tags.map((tag) => (
                                <div key={tag.id} className="me-2 mb-2 tag-container">
                                    <span className="tag-name">#{tag.name}</span>
                                </div>
                            ))}
                        </div>
                    </div>
                )}
            </div>
            <style>{`
                    .modal-container {
                        max-height: 600px; /* Restrict content height to the viewport */
                        overflow-y: auto; /* Allow vertical scrolling */
                    }
					.sponsor-box {
					    height: 100px;
                        border: 1px solid #EFF0F3;
					}
					.speakers-box {
					    height: 65px;
						width: auto;
                        display: inline-flex;
					}
					.image-size{
					    object-fit: cover;
					    height: 74px;
						width: 100px;	
					}
					.speaker-image {
					    height: 30px;
						width: 30px;
						border-radius: 50%;
						margin-bottom: 5px;
					}
					.speaker-name {
						font-family: Prompt;
                        font-size: 12px;
                        font-weight: 500;
                        line-height: 12px;
						margin-bottom: 4px;
					}
					.speaker-main {
						font-family: Prompt;
                        font-size: 10px;
                        font-weight: 600;
					}
					.poc-designation {
						color: #8B8D98;
					}
					.poc-organization{
						color: #62636C;
					}
					.speaker-designation {
						color: #8B8D98;
					}
					.speaker-organization{
						color: #62636C;
					}
					.poc-box {
					    height: 170px;
						background: #FDB14B14;
                        border: 1px solid #FDB14B;
                        radius: 4px;
					}
					.booth-box {
					    height: 46px;
						width: 100%;
					    background: #FDB14B14;
						font-family: Prompt;
						font-weight: 500;
						font-size: 16px;
						line-height: 24px;
						color: #000000;
                        radius: 8px;
					}
					.booth-user-box {
					    height: 44px;
						width: auto;
                        display: inline-flex;	
					}
					.poc-image{
					    width: 44px;
						height: 44px;
						border-radius: 50%;
						margin-right: 10px;
					}
					.contact-image{
					    margin-right: 5px;
					}
					.sponsor-info{
					    font-family: Prompt;
						font-weight: 500;
						color: #000000;
					}
					.sponsor-description{
					    font-family: Prompt;
						font-weight: 400;
						font-size: 14px;
						line-height: 22px;
						color: #1E1F24;
					}
					.sponsor-name{
					    font-size: 14px;
						line-height: 20px;
						margin-bottom: 5px;
					}	
					.location{
					    font-size: 12px;
						line-height: 16px;
					}
					.booth-text{
					    font-family: Prompt;
                        font-size: 12px;
                        font-weight: 600;
                        line-height: 17px;
						color: #62636C;
					}
					.poc-name{
					    font-family: Prompt;
                        font-size: 12px;
                        font-weight: 600;
                        line-height: 17px;
						color: #62636C;
					}
					.poc-text{
					    font-family: Prompt;
                        font-size: 12px;
                        font-weight: 600;
                        line-height: 17px;
						color: #62636C;
					}
					.contact-text{
					    font-family: Prompt;
                        font-size: 16px;
                        font-weight: 600;
                        line-height: 22px;
						color: #1E1F24;
					}
					.representative-text{
					    font-family: Prompt;
                        font-size: 18px;
                        font-weight: 600;
                        line-height: 25px;
						color: #000000;
					}
					.tag-header{
                      font-family: "Prompt";
                      font-weight: 600;
                      font-size: 18px;
                      line-height: 24px;
                      color: #1E1F24;
                    }
                    .tag-container{
                      background: #FDB14B14;
                      height: 28px;
                      padding: 0px 12px 0px 12px;
                      border-radius: 4px;
                    }
                    .tag-name{
                      font-family: "Hind";
                      font-weight: 600;
                      font-size: 12px;
                      line-height: 20px;
                      color: #000000;
                    }
				`}</style>
        </ProSideViewModal>
    )
}

export default SideViewSponsorPro;