import React, { Fragment } from "react";
import Select, { components } from "react-select";
import moment from "moment";

const Menu = (props) => {
  return (
    <Fragment>
      <components.Menu {...props}>
        <div>
          {props.selectProps.fetchingData ? (
            <span className="fetching">Fetching data...</span>
          ) : (
            <div>{props.children}</div>
          )}
        </div>
      </components.Menu>
    </Fragment>
  );
};

const Option = (props) => {
  return (
    <Fragment>
      <components.Option {...props}>{props.children}</components.Option>
    </Fragment>
  );
};

const AgendaDatesMobileDropdown = ({
  agendaDates,
  setSelectedTrackDate,
  selectedTrackDate,
}) => {
  const options = agendaDates.map((date) => ({
    value: date,
    label: moment(date).format("dddd, MMM D"),
  }));

  const handleChange = (selectedOption) => {
    setSelectedTrackDate(selectedOption.value);
  };

  const customStyles = {
    control: (base) => ({
      ...base,
      width: "240px",
      height: "47px",
      border: "0.8px solid #CACACA",
      borderRadius: "5px",
      boxShadow: "none",
      "@media (max-width: 768px)": {
        width: "100%", // Full width for mobile screens
      },
    }),
    option: (base, { isFocused, isSelected }) => ({
      ...base,
      backgroundColor: isSelected ? "#F6F6FC" : "white",
      color: isSelected ? "#000000" : "#333333",
      cursor: "pointer",
    }),
    menu: (base) => ({
      ...base,
      borderRadius: "5px",
      overflow: "hidden",
    }),
  };

  return (
    <div>
      <Select
        options={options}
        components={{ Menu, Option }}
        value={options.find((opt) => opt.value === selectedTrackDate)}
        onChange={handleChange}
        styles={customStyles}
      />
    </div>
  );
};

export default AgendaDatesMobileDropdown;
