import React, { useState, useEffect } from 'react';
import Select, { components } from 'react-select';

const { Option } = components;

const CustomOption = (props) => {
  return (
    <Option {...props}>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <span>{props.data.label}</span>
      </div>
    </Option>
  );
};

const AgendaTrendyTracksDropdown = ({ agendaTracks, onTrackSelect }) => {
  const [selectedOption, setSelectedOption] = useState(null);

  // Convert agendaTracks into the react-select options format
  const options = agendaTracks.map((track) => ({
    value: track.track_id,
    label: track.track_title,
  }));

  // Select the first track by default when the component mounts
  useEffect(() => {
    if (agendaTracks.length > 0) {
      setSelectedOption(options[0]);
      onTrackSelect(agendaTracks[0]);
    }
  }, [agendaTracks]);

  // Handle selection change
  const handleChange = (option) => {
    setSelectedOption(option);
    const selectedTrack = agendaTracks.find((track) => track.track_id === option.value);
    onTrackSelect(selectedTrack);
  };

  return (
    <div className="mb-3 mt-3 mt-md-0 mt-lg-0 mt-xl-0 d-lg-flex d-md-flex justify-content-end">
      <Select
        options={options}
        value={selectedOption} // Controlled value
        onChange={handleChange}
        components={{ Option: CustomOption }}
        styles={{
          control: (base) => ({
            ...base,
            width: '240px',
            height: '47px',
            border: '0.8px solid #CACACA',
            borderRadius: '5px',
            boxShadow: 'none',
            '@media (max-width: 768px)': { // Apply media query for small screens
              width: '100%', // Full width for mobile screens
            },
          }),
          option: (base, { isFocused, isSelected }) => ({
            ...base,
            backgroundColor: isSelected ? '#F6F6FC' : 'white',
            color: isSelected ? '#000000' : '#333333',
            cursor: 'pointer',
          }),
          menu: (base) => ({
            ...base,
            borderRadius: '5px',
            overflow: 'hidden',
          }),
        }}
      />
    </div>
  );
};

export default AgendaTrendyTracksDropdown;

