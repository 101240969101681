import { AnimatePresence, motion } from 'framer-motion'
import React from 'react'

const SideViewModal = ({ children, isOpen, setIsOpen, background = "#FFF", isMenu = false }) => {
    return (
        <>
            <AnimatePresence>
                {isOpen && (
                    <>
                        <motion.div
                            className='position-fixed d-flex justify-content-center align-items-center vh-90 top-0 side-view-pro-modal-cont'
                            initial={{ x: -200 }}
                            animate={{
                                x: 10
                            }}
                            
                            transition={{ type: "easyInOut", bounce: 0, duration: 0.5 }}
                        >
                            {children}
                        </motion.div>
                        <motion.div
                            initial={{ opacity: 0 }}
                            animate={{
                                opacity: 1
                            }}
                            exit={{
                                opacity: 0
                            }}
                            transition={{ type: "spring", bounce: 0, duration: 0.1 }}
                            onClick={() => setIsOpen((isOpen) => !isOpen)}
                            style={{ background: "rgba(0, 0, 0, 0.75)", zIndex: "3001"}}
                            className="px-5 position-fixed vh-100 vw-100 d-flex align-items-center justify-content-center top-0 start-0 ani-height"
                        />
                    </>
                )}
            </AnimatePresence>
            <style>{`
                .side-view-pro-modal-cont {
                    margin:120px;
                    display: flex;
                    background: ${background}; 
                    z-index: 3002; 
                    width : 770px;
                    height: auto;
                    min-width: 320px;
                    transition: easy-in-out;
                }
                .ani-height{
                    height: 100% !important;
                }


                @media (max-width: 1200px) {
                    .side-view-pro-modal-cont {
                        margin:120px;
                        transform: translateX(0px) translateZ(0px) !important;
                        width: 60%;
                        z-index: 3002; 
                        max-width: ${isMenu ? "320px" : "600px"};
                        min-width: 320px;
                    }
                }

                @media (max-width: 768px) {
                    .side-view-pro-modal-cont {
                        margin-top:100px;
                        margin-bottom:100px;
                        margin-left:10px;
                        margin-right:10px;
                        transform: translateX(0px) translateZ(0px) !important;
                        width: 374px !important
                        z-index: 3002; 
                        height: 630px !important;
                    }
                }
                @media (max-width: 576px) {

                .side-view-pro-modal-cont {
                        overflow: auto !important;
                        max-height: 450px !important;
                        height: auto !important;
                        padding-top: 165px;
                        right: 3px !important;
                        width: 94% !important;
                   }
                }
            `}</style>
        </>
    )
}

export default SideViewModal
