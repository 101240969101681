// File: constants/tabs.js
export const TABS = {
    ABOUT: "about",
    TICKETS: "tickets",
    SPEAKERS: "speakers",
    WORKSHOPS: "workshops",
    AGENDA: "agenda",
    SPONSORS: "sponsors",
    PARTNERS: "partners",
    EXHIBITORS: "exhibitors",
    VENUE: "venue",
  };
  