import React, { useState, useEffect } from 'react';
import { getAgenda } from '../../../../crud/dedicatedEvent-crud';
import { errorToaster } from "../../../../helpers/utils";
import moment from 'moment';
import DateDropdown from '../../../../components/CustomDropdown/DateDropdown';
import Loading from '../../../../components/Loading';
import AgendaDatesTab from '../../GeneralComponents/Agenda/AgendaDatesTab';
import AgendaDatesMobileDropdown from '../../GeneralComponents/Agenda/AgendaDatesMobileDropdown';
import AgendaTrendySessions from './AgendaTrendySession';
import AgendaTrendyTracks from './AgendaTrendyTrack';

const customStyles = {
    option: (styles, { isFocused, isSelected }) => ({
        ...styles,
        background: isFocused ? "white" : isSelected ? "white" : undefined,
        color: isFocused ? "#FB5850" : isSelected ? "#FB5850" : undefined,
        zIndex: 1,
        cursor: 'pointer',
    }),
    dropdownIndicator: (base) => ({
        ...base,
        color: "#FFFFFF",
        "&:hover": {
            color: "#572148",
        },
    }),
    control: (base, state) => ({
        ...base,
        background: "#FB5850",
        color: '#FFFFFF',
        border: state.isFocused ? "1px solid #572148" : "1px solid #cdd4db",
        boxShadow: "none",
        fontFamily: 'Prompt',
        fontWeight: '600',
        fontSize: '16px',
        lineHeight: '120%',
    }),
    singleValue: (defaultStyles) => ({
        ...defaultStyles,
        left: '20%',
        color: '#FFFFFF',
    }),
};

const EventAgenda = ({ eventData }) => {
    const [loading, setLoading] = useState(false);
    const [agenda, setAgenda] = useState([]);
    const [errorMessage, setErrorMessage] = useState('');
    const [agendaDates, setAgendaDates] = useState([]);
    const [selectedTrackId, setSelectedTrackId] = useState('');

    const today = moment(eventData.start_date).format("YYYY-MM-DD");
    const [dateValue, setDateValue] = useState(today);

    useEffect(() => {
        fetchAgendaData(dateValue);
        calculateAgendaDates();
    }, [dateValue]);

    const fetchAgendaData = async (date) => {
        setLoading(true);
        try {
            const response = await getAgenda(eventData.event_id, date);
            const agendaData = response.data || [];
            setAgenda(agendaData);
            setSelectedTrackId(agendaData.length > 0 ? agendaData[0].track_id : '');
        } catch (error) {
            setErrorMessage(error.response?.data?.error || "Failed to load agenda");
        } finally {
            setLoading(false);
        }
    };

    const calculateAgendaDates = () => {
        const dates = [];
        const start = moment(eventData.start_date);
        const end = moment(eventData.end_date);

        while (start.isSameOrBefore(end, 'day')) {
            dates.push(start.format('YYYY-MM-DD'));
            start.add(1, 'day');
        }

        setAgendaDates(dates);
    };

    return (
        <div id="agenda">
            <div className="d-flex justify-content-lg-center align-items-center pt-5 px-2">
                <span className="section-title mb-3 mb-lg-0 mb-md-0 mb-xl-0">Agenda</span>
            </div>
            <div className="d-block d-md-none d-lg-none d-xl-none px-2">
                {agendaDates.length > 1 && dateValue && <AgendaDatesMobileDropdown
                    agendaDates={agendaDates}
                    selectedTrackDate={dateValue}
                    setSelectedTrackDate={setDateValue}
                />}
            </div>
            <div className="date-change-div d-none d-md-block d-lg-block d-xl-block mt-2 mt-lg-5 mt-md-5">
                {agendaDates.length > 1 && (
                    <AgendaDatesTab
                        agendaDates={agendaDates}
                        selectedTrackDate={dateValue}
                        setSelectedTrackDate={setDateValue}
                        selectedDateBackground="#FB5850"
                        otherTextColor="#572148"
                        background="white"
                    />
                )}
            </div>
            {loading ? (
                <div className="loading d-flex justify-content-center align-items-center">
                    <Loading color="#FB5850" />
                </div>
            ) : agenda.length > 0 ? (
                <div className="pro-container px-2">
                    <AgendaTrendyTracks
                        agendaTracks={agenda}
                        onTrackSelect={(selectedTrack) => setSelectedTrackId(selectedTrack.track_id)}
                    />
                    <AgendaTrendySessions
                        selectedTrackData={agenda.filter((track) => track.track_id === selectedTrackId)}
                        eventData={eventData}
                    />
                </div>
            ) : (
                <div className="no-agenda-text mt-2">{errorMessage}</div>
            )}
            <style jsx global>{`
                #agenda {
                    padding: 0 50px 50px 50px;
                    background: #F6F4EF;
                }
                .section-title {
                    font-family: 'Prompt';
                    font-weight: 600;
                    font-size: 40px;
                    line-height: 52px;
                    color: #572148;
                }
                .date-change-div {
                    display: flex;
                    align-items: center;
                }
                .loading {
                    margin-top: 60px;
                    padding-bottom: 50px;
                }
                .no-agenda-text {
                    font-family: 'Caveat Brush';
                    font-weight: 400;
                    font-size: 30px;
                    line-height: 120%;
                    color: #fff;
                    margin-top: 60px;
                    padding-bottom: 60px;
                }
                @media only (max-width: 768px) {
                    #agenda {
                    padding: 0 10px 50px 10px;
                    }
                    .section-title {
                    font-weight: 600;
                    font-size: 18px;
                    line-height: 24px;
                    }    
                }
            `}</style>
        </div>
    );
};

export default EventAgenda;
