export const eventSampleData = {
	event_id: "a27440f9-2a0b-4ad0-9442-482b1bb05d32",
	name: "Advanced Technology Expo First Edition",
	event_short_name: "ATE-V1",
	event_url: "ATE-V1",
	venue: null,
	latitude: null,
	longitude: null,
	city: null,
	state: null,
	country: null,
	expected_speakers: 15,
	expected_attendees: 900,
	is_virtual: true,
	event_website: null,
	description:
		'<div class="w-full border-b border-black/10 dark:border-gray-900/50 text-gray-800 dark:text-gray-100 group bg-gray-50 dark:bg-[#444654]">\n<div class="text-base gap-4 md:gap-6 m-auto md:max-w-2xl lg:max-w-2xl xl:max-w-3xl p-4 md:py-6 flex lg:px-0">\n<div class="relative flex w-[calc(100%-50px)] md:flex-col lg:w-[calc(100%-115px)]">\n<div class="flex flex-grow flex-col gap-3">\n<div class="min-h-[20px] flex flex-col items-start gap-4 whitespace-pre-wrap">\n<div class="markdown prose w-full break-words dark:prose-invert dark">\n<h2 class="h1-font white-color">Advanced Technology Expo&nbsp;</h2>\n<p style="margin: 0px 0px;">is a premier event for professionals in the fields of web development, artificial intelligence, machine learning, and ethical hacking. Attendees will have the opportunity to learn from industry leaders and experts, network with peers and potential employers, and stay up-to-date on the latest technologies and trends in these rapidly-evolving fields. The conference will feature keynote speeches, panel discussions, workshops, and exhibitor booths, as well as ample opportunities for networking and professional development. Whether you\'re a seasoned veteran or just starting out, this conference is a must-attend for anyone interested in advancing their career in the tech industry.</p>\n</div>\n</div>\n</div>\n<div class="text-gray-400 flex self-end lg:self-center justify-center mt-2 gap-4 lg:gap-1 lg:absolute lg:top-0 lg:translate-x-full lg:right-0 lg:mt-0 lg:pl-2 visible">&nbsp;</div>\n</div>\n</div>\n</div>\n<p style="font-family: Hind, sans-serif;">&nbsp;</p>',
	tax_percentage: 18,
	pg_percentage: 4,
	currency_id: 1,
	currency_name: "INR",
	tax_name: "GST",
	is_pg_charge_inclusive: false,
	is_tax_inclusive: true,
	is_free: false,
	event_twitter_url: null,
	event_linkedin_url: null,
	event_facebook_url: null,
	consents: [
		{
			consent_id: "d39dbc",
			is_checked: false,
			is_deleted: false,
			is_editable: false,
			consent_name: "KonfHub Subscription",
			consent_text:
				"<p>I provide consent for KonfHub to contact me for any future events or marketing updates through Email, SMS, or WhatsApp.</p>",
			is_deletable: false,
			is_mandatory: false
		},
		{
			consent_id: "3521fc",
			is_checked: false,
			is_deleted: false,
			is_editable: true,
			consent_name: "Consent To Organiser",
			consent_text:
				"<p>I accept to receive SMS and WhatsApp updates from the event organizer.</p>",
			is_deletable: true,
			is_mandatory: false
		}
	],
	start_date: "2023-02-23",
	end_date: "2023-02-24",
	time_zone: "Asia/Kolkata",
	start_time: "04:30:00",
	end_time: "12:30:00",
	event_poster_url:
		"https://dev-media.konfhub.com/default-event-posters/default-event-posters-image5.jpg",
	organiser_name: "KonfHub Technologies LLP",
	organiser_dial_code: "+91",
	organiser_country_code: "in",
	organiser_phone: "9876543210",
	organiser_email: "reachus@konfhub.com",
	organiser_website: "https://konfhub.com",
	organiser_image_url:
		"https://dev-media.konfhub.com/past_events/2023/January/24/1674541394031-konfhub-octopus.png",
	organizer_twitter_url: "https://twitter.com/konfhub",
	organizer_linkedin_url: "https://www.linkedin.com/in/konfhub/",
	organizer_facebook_url: "https://www.facebook.com/konfhub/",
	organiser_info:
		'<div class="jsx-e281c3aeffff3630 about_title">We Are Not Just</div>\n<h2 class="jsx-e281c3aeffff3630"><span class="jsx-e281c3aeffff3630 about_title">Another <span class="jsx-e281c3aeffff3630 ticketing_text">Ticketing <img class="spark-img-about" src="https://konfhub.com/img/spark.svg" srcset="https://konfhub.com/img/spark.svg 1x, https://konfhub.com/img/spark.svg 2x" alt="This is an orange picture showing a spark" width="34" height="34" loading="lazy" data-nimg="future"></span>Platform! </span></h2>\n<div class="jsx-e281c3aeffff3630 about_text mt-4">Creating your event and making it successful is as simple as One.. Two.. Three! One: <strong class="jsx-e281c3aeffff3630">Create</strong> an event and go live within minutes. Two:<strong class="jsx-e281c3aeffff3630">Gamify</strong> your events to keep your audience engaged. Three: <strong class="jsx-e281c3aeffff3630">Amplify </strong>your event and reach more people through contests &amp; through multiple channels.</div>',
	keywords: [],
	event_live_link: null,
	business_name: null,
	business_address: null,
	is_taxable: false,
	tax_country: "India",
	tax_consent: "false",
	has_speakers: true,
	speaker_section_title: "Event Speakers",
	speaker_section_description:
		'<p style="font-family: Hind, sans-serif;">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>',
	has_sponsors: true,
	sponsor_section_title: "Event Sponsor(s)",
	sponsor_section_description:
	'<p style="font-family: Hind, sans-serif;">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident.</p>',
	has_partners: true,
	partner_section_title: "Event Partner(s)",
	partner_section_description:
		'<p style="font-family: Hind, sans-serif;">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident.</p>',
	workshop_section_title: "Tech Workshops",
	has_workshops: true,
	workshop_section_description:
		'<p style="font-family: Hind, sans-serif;">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>',
	has_agenda: false,
	no_of_workshops: 4,
	event_template: 1
};

export const pricingSampleData = {
	uncategorized: [
		{
			ticket_name: "Community Ticket",
			ticket_type: "Free Ticket",
			currency_name: "INR",
			ticket_price: 0,
			start_timestamp: "2023-01-24 01:56:45.529",
			end_timestamp: "2023-02-24 12:30:00",
			description: ""
		},
		{
			ticket_name: "Regular Ticket",
			ticket_type: "Paid Ticket",
			currency_name: "INR",
			ticket_price: 1000,
			description: "This ticket contains SWAGS!",
			start_timestamp: "2023-01-24 01:56:45.529",
			end_timestamp: "2023-02-24 12:30:00"
		}
	],
	categorized: []
};

export const sampleSpeakersData = [
	{
		name: "Arun Kumar",
		image_url:
			"https://dev-media.konfhub.com/speakers/2023/January/24/1674526182598-images (1).jpeg",
		designation: "Software Engineer",
		organisation: "Wipro",
		linkedin_url: null,
		facebook_url: null,
		twitter_url: null,
		website_url: null,
		speaker_order: 1
	},
	{
		name: "Sreejith A ",
		image_url:
			"https://dev-media.konfhub.com/speakers/2023/January/24/1674526237022-images.jpeg",
		designation: "Software Engineer",
		organisation: "Infosys",
		linkedin_url: null,
		facebook_url: null,
		twitter_url: null,
		website_url: null,
		speaker_order: 2
	},
	{
		name: "Goutham",
		image_url:
			"https://dev-media.konfhub.com/speakers/2023/January/24/1674539082294-images (7).jpeg",
		designation: "Software engineer",
		organisation: "IBM",
		linkedin_url: null,
		facebook_url: null,
		twitter_url: null,
		website_url: null,
		speaker_order: 3
	},
	{
		name: "Sarath",
		image_url:
			"https://dev-media.konfhub.com/speakers/2023/January/24/1674539109375-images (6).jpeg",
		designation: "Software engineer",
		organisation: "Wipro",
		linkedin_url: null,
		facebook_url: null,
		twitter_url: null,
		website_url: null,
		speaker_order: 4
	},
	{
		name: "Vivek",
		image_url:
			"https://dev-media.konfhub.com/speakers/2023/January/24/1674539136460-images (5).jpeg",
		designation: "Software engineer",
		organisation: "Infosys",
		linkedin_url: null,
		facebook_url: null,
		twitter_url: null,
		website_url: null,
		speaker_order: 5
	},
	{
		name: "Ashish",
		image_url:
			"https://dev-media.konfhub.com/speakers/2023/January/24/1674539178434-images (4).jpeg",
		designation: "Software Engineer",
		organisation: "Amazon",
		linkedin_url: null,
		facebook_url: null,
		twitter_url: null,
		website_url: null,
		speaker_order: 6
	},
	{
		name: "Ashok",
		image_url:
			"https://dev-media.konfhub.com/speakers/2023/January/24/1674539207208-images (3).jpeg",
		designation: "Software Engineer",
		organisation: "Razorpay",
		linkedin_url: null,
		facebook_url: null,
		twitter_url: null,
		website_url: null,
		speaker_order: 7
	},
	{
		name: "Vivek",
		image_url:
			"https://dev-media.konfhub.com/speakers/2023/January/24/1674539255671-download (8).jpeg",
		designation: "Software Engineer",
		organisation: "IBM",
		linkedin_url: null,
		facebook_url: null,
		twitter_url: null,
		website_url: null,
		speaker_order: 8
	},
	{
		name: "Ashutosh",
		image_url:
			"https://dev-media.konfhub.com/speakers/2023/January/24/1674539394951-download (7).jpeg",
		designation: "Software Engineer",
		organisation: "Microsoft",
		linkedin_url: null,
		facebook_url: null,
		twitter_url: null,
		website_url: null,
		speaker_order: 9
	},
	{
		name: "Ramesh",
		image_url:
			"https://dev-media.konfhub.com/speakers/2023/January/24/1674539426838-download (6).jpeg",
		designation: "Software Engineer",
		organisation: "Wipro",
		linkedin_url: null,
		facebook_url: null,
		twitter_url: null,
		website_url: null,
		speaker_order: 10
	}
];

export const sampleSponsorsData = {
    categorized: [
        {
            category_id: 1552,
            category_name: "Platinum",
            category_order: 1,
            entity: [
                {
                    sponsor_id: 2019,
                    entity_name: "AWS",
                    website_url: "https://aws.amazon.com/",
                    image_url: "https://dev-media.konfhub.com/sponsors/2023/January/24/1674541133719-aws.png",
                    sponsor_order: 1,
                    sponsor_category_id: 1552,
                    location: "Seattle, WA",
                    description: "AWS is a comprehensive cloud computing platform offering scalable solutions.",
                    booth_number: "B101",
                    poc: {
                        name: "John Doe",
                        designation: "Cloud Solutions Architect",
                        organization: "Amazon Web Services",
                        image_url: "https://dev-media.konfhub.com/speakers/2023/January/24/1674526182598-images (1).jpeg",
                        dial_code: "+1",
                        contact_number: "1234567890",
                        email: "john.doe@aws.com"
                    },
                    representatives: [
                        {
                            name: "Jane Smith",
                            designation: "Senior Developer Advocate",
                            organization: "AWS",
                            image_url: "https://dev-media.konfhub.com/speakers/2023/January/24/1674526182598-images (1).jpeg",
                            representative_order: 1
                        },
                        {
                            name: "Carlos Sanchez",
                            designation: "Infrastructure Specialist",
                            organization: "AWS",
                            image_url: "https://dev-media.konfhub.com/speakers/2023/January/24/1674526182598-images (1).jpeg",
                            representative_order: 2
                        },
                        {
                            name: "Priya Sharma",
                            designation: "Data Engineer",
                            organization: "AWS",
                            image_url: "https://dev-media.konfhub.com/speakers/2023/January/24/1674526182598-images (1).jpeg",
                            representative_order: 3
                        }
                    ],
                    tags: [
                        { id: 1, name: "Cloud" },
                        { id: 2, name: "DevOps" },
                        { id: 7, name: "Scalability" },
                        { id: 8, name: "Security" }
                    ]
                },
                {
                    sponsor_id: 2020,
                    entity_name: "Atlassian",
                    website_url: "https://www.atlassian.com/",
                    image_url: "https://dev-media.konfhub.com/sponsors/2023/January/24/1674541263234-atl.png",
                    sponsor_order: 2,
                    sponsor_category_id: 1552,
                    location: "Sydney, Australia",
                    description: "Atlassian creates collaboration tools like Jira and Confluence.",
                    booth_number: "B102",
                    poc: {
                        name: "Emma Wilson",
                        designation: "Product Manager",
                        organization: "Atlassian",
                        image_url: "https://dev-media.konfhub.com/speakers/2023/January/24/1674526182598-images (1).jpeg",
                        dial_code: "+61",
                        contact_number: "987654321",
                        email: "emma.wilson@atlassian.com"
                    },
                    representatives: [
                        {
                            name: "Liam Brown",
                            designation: "UX Specialist",
                            organization: "Atlassian",
                            image_url: "https://dev-media.konfhub.com/speakers/2023/January/24/1674526182598-images (1).jpeg",
                            representative_order: 1
                        },
                        {
                            name: "Sophia Lee",
                            designation: "Scrum Master",
                            organization: "Atlassian",
                            image_url: "https://dev-media.konfhub.com/speakers/2023/January/24/1674526182598-images (1).jpeg",
                            representative_order: 2
                        }
                    ],
                    tags: [
                        { id: 3, name: "Collaboration" },
                        { id: 4, name: "Agile" },
                        { id: 9, name: "Productivity" },
                        { id: 10, name: "Workflow" }
                    ]
                }
            ]
        },
        {
            category_id: 1553,
            category_name: "Diamond",
            category_order: 2,
            entity: [
                {
                    sponsor_id: 2023,
                    entity_name: "Whatsapp",
                    website_url: "https://www.whatsapp.com/",
                    image_url: "https://dev-media.konfhub.com/sponsors/2023/January/24/1674541730259-Wap.png",
                    sponsor_order: 4,
                    sponsor_category_id: 1553,
                    location: "Menlo Park, CA",
                    description: "Whatsapp is a globally used instant messaging platform.",
                    booth_number: "D201",
                    poc: {
                        name: "Mike Johnson",
                        designation: "Technical Lead",
                        organization: "Meta",
                        image_url: "https://dev-media.konfhub.com/speakers/2023/January/24/1674526182598-images (1).jpeg",
                        dial_code: "+1",
                        contact_number: "1234567891",
                        email: "mike.johnson@meta.com"
                    },
                    representatives: [
                        {
                            name: "Alicia Torres",
                            designation: "Communication Strategist",
                            organization: "Meta",
                            image_url: "https://dev-media.konfhub.com/speakers/2023/January/24/1674526182598-images (1).jpeg",
                            representative_order: 1
                        },
                        {
                            name: "Rajesh Gupta",
                            designation: "Back-End Developer",
                            organization: "Meta",
                            image_url: "https://dev-media.konfhub.com/speakers/2023/January/24/1674526182598-images (1).jpeg",
                            representative_order: 2
                        }
                    ],
                    tags: [
                        { id: 5, name: "Messaging" },
                        { id: 6, name: "Communication" },
                        { id: 11, name: "Instant Messaging" },
                        { id: 12, name: "Connectivity" }
                    ]
                }
            ]
        }
    ],
    uncategorized: []
};



export const sampleWorkshopsData = [
	{
		workshop_title: "Ethical Hacking Workshop",
		workshop_description:
			'<p style="font-family: Hind, sans-serif;">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>',
		workshop_banner_url:
			"https://dev-media.konfhub.com/sponsors/2023/January/24/1674526464763-download (2).jpeg",
		start_timestamp: "2023-01-25 10:00:00",
		end_timestamp: "2023-01-26 18:00:00",
		is_virtual: true,
		venue: "",
		city: null,
		latitude: null,
		longitude: null,
		workshop_order: 1,
		workshop_speakers: [
			{
				speaker_id: 3328,
				name: "Arun Kumar",
				about: null,
				image_url:
					"https://dev-media.konfhub.com/speakers/2023/January/24/1674526182598-images (1).jpeg",
				designation: "Software Engineer",
				organisation: "Wipro",
				linkedin_url: null,
				twitter_url: null,
				facebook_url: null,
				website_url: null
			}
		],
		workshop_tickets: [
			{
				ticket_id: 35631,
				ticket_name: "Community Ticket",
				ticket_type: 1,
				ticket_date: null,
				start_timestamp: "2023-01-24 01:56:45.529",
				end_timestamp: "2023-02-24 12:30:00",
				ticket_price: 0,
				description: "",
				max_tickets: 100,
				min_tickets: 1,
				no_of_tickets: 1000,
				is_group_ticket: false,
				sold_out: false,
				hidden_ticket: false,
				waitlist_enabled: false,
				is_free: true,
				currency_id: 1,
				currency_name: "INR",
				duplicates_allowed: false,
				whatsapp_enabled: false,
				ticket_applicability: null
			}
		]
	},
	{
		workshop_title: "AI&ML Workshop",
		workshop_description:
			'<p style="font-family: Hind, sans-serif;">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>',
		workshop_banner_url:
			"https://dev-media.konfhub.com/sponsors/2023/January/24/1674526747899-download (3).jpeg",
		start_timestamp: "2023-01-25 10:00:00",
		end_timestamp: "2023-01-26 18:00:00",
		is_virtual: false,
		venue: "Hyatt Regency Delhi, Ring Road, Bhikaji Cama Place, Rama Krishna Puram, New Delhi, Delhi, India",
		city: "New Delhi",
		latitude: 28.5691385,
		longitude: 77.1857125,
		workshop_order: 2,
		workshop_speakers: [
			{
				speaker_id: 3329,
				name: "Sreejith A ",
				about: null,
				image_url:
					"https://dev-media.konfhub.com/speakers/2023/January/24/1674526237022-images.jpeg",
				designation: "Software Engineer",
				organisation: "Infosys",
				linkedin_url: null,
				twitter_url: null,
				facebook_url: null,
				website_url: null
			},
			{
				speaker_id: 3328,
				name: "Arun Kumar",
				about: null,
				image_url:
					"https://dev-media.konfhub.com/speakers/2023/January/24/1674526182598-images (1).jpeg",
				designation: "Software Engineer",
				organisation: "Wipro",
				linkedin_url: null,
				twitter_url: null,
				facebook_url: null,
				website_url: null
			}
		],
		workshop_tickets: [
			{
				ticket_id: 35632,
				ticket_name: "Regular Ticket",
				ticket_type: 2,
				ticket_date: null,
				start_timestamp: "2023-01-24 01:56:45.529",
				end_timestamp: "2023-02-24 12:30:00",
				ticket_price: 1000,
				description: "",
				max_tickets: 100,
				min_tickets: 1,
				no_of_tickets: 1000,
				is_group_ticket: false,
				sold_out: false,
				hidden_ticket: false,
				waitlist_enabled: false,
				is_free: false,
				currency_id: 1,
				currency_name: "INR",
				duplicates_allowed: false,
				whatsapp_enabled: false,
				ticket_applicability: null
			}
		]
	},
	{
		workshop_title: "Web Development Workshop",
		workshop_description:
			'<p style="font-family: Hind, sans-serif;">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>',
		workshop_banner_url:
			"https://dev-media.konfhub.com/sponsors/2023/January/24/1674528449911-download (1).png",
		start_timestamp: "2023-01-25 10:00:00",
		end_timestamp: "2023-01-26 18:00:00",
		is_virtual: true,
		venue: "",
		city: null,
		latitude: null,
		longitude: null,
		workshop_order: 3,
		workshop_speakers: [],
		workshop_tickets: []
	},
	{
		workshop_title: "Cybersecurity Awareness Workshop",
		workshop_description:
			'<p style="font-family: Hind, sans-serif;">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>',
		workshop_banner_url:
			"https://dev-media.konfhub.com/sponsors/2023/January/24/1674528449911-download (1).png",
		start_timestamp: "2023-01-25 10:00:00",
		end_timestamp: "2023-01-26 18:00:00",
		is_virtual: true,
		venue: "",
		city: null,
		latitude: null,
		longitude: null,
		workshop_order: 4,
		workshop_speakers: [
			{
				speaker_id: 3329,
				name: "Sreejith A ",
				about: null,
				image_url:
					"https://dev-media.konfhub.com/speakers/2023/January/24/1674526237022-images.jpeg",
				designation: "Software Engineer",
				organisation: "Infosys",
				linkedin_url: null,
				twitter_url: null,
				facebook_url: null,
				website_url: null
			}
		],
		workshop_tickets: []
	}
];
