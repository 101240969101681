import { ENTITY_TYPES } from "../config/constants";
import { rawAxios as axios } from "./axios";
import { api } from "./axios.config";

const API_URL = api.apiUrl;

const GET_TICKETS = "/event/url/";
const GET_EVENTS = "/event/public/";

/**
 *
 * @param url
 */
export function getEventData(url) {
	return axios.get(API_URL + GET_EVENTS + url);
}

/**
 *
 * @param url
 */
export function getTicketData(url) {
	return axios.get(API_URL + GET_TICKETS + url + "/tickets");
}

/**
 *
 * @param url
 */
export function getSpeakers(url) {
	return axios.get(API_URL + GET_EVENTS + url + "/speakers");
}

/**
 *
 * @param url
 */
export function getSponsors(url) {
	return axios.get(API_URL + GET_EVENTS + url + `/entity/${ENTITY_TYPES.SPONSORS}`);
}
export function getPartners(url) {
	return axios.get(API_URL + GET_EVENTS + url + `/entity/${ENTITY_TYPES.PARTNERS}`);
}
export function getExhibitors(url) {
	return axios.get(API_URL + GET_EVENTS + url + `/entity/${ENTITY_TYPES.EXHIBITORS}`);
}

/**
 *
 * @param url
 */
export function getWorkshops(url) {
	return axios.get(API_URL + GET_EVENTS + url + "/workshops");
}

/**
 *
 * @param event_id
 * @param payload
 */
export function getAgenda(event_id, payload) {
	let QUERY = `?track_date=${payload}`;
	return axios.get(API_URL + "/event/" + event_id + "/public" + "/tracks" + QUERY);
}
